<template>
  <v-container height="100" class="pa-0 mt-0">
    <h6 class="my-4">
      Төлөвлөсөн газрууд <span v-if="planned">({{ planned.length }})</span>
    </h6>
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-mapbox
          access-token="pk.eyJ1IjoiZ3JhbmRpdHh4ayIsImEiOiJja25mcDYxdHYycjRnMzFwaGx0ZWVwNGhpIn0.EBks4tBwxFPv66eqLJfTWQ"
          map-style="mapbox://styles/mapbox/satellite-streets-v11"
          :center="[106.9057, 47.8864]"
          :zoom="5"
          id="map"
          ref="map"
          height="100vh"
        ></v-mapbox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TripCardSimple from "./TripCardSimple.vue";
import mainUI from "./mainUI.vue";
import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";
export default {
  components: {
    TripCardSimple,
    mainUI,
  },
  data() {
    const srcs = {
      1: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
      2: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
      3: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
      4: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
      5: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
    };
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      autoUpdate: true,
      friends: ["Sandra Adams", "Britta Holt"],
      isUpdating: false,
      name: "Midnight Crew",
      people: [
        { header: "Group 1" },
        { name: "Sandra Adams", group: "Group 1", avatar: srcs[1] },
        { name: "Ali Connors", group: "Group 1", avatar: srcs[2] },
        { name: "Trevor Hansen", group: "Group 1", avatar: srcs[3] },
        { name: "Tucker Smith", group: "Group 1", avatar: srcs[2] },
        { divider: true },
        { header: "Group 2" },
        { name: "Britta Holt", group: "Group 2", avatar: srcs[4] },
        { name: "Jane Smith ", group: "Group 2", avatar: srcs[5] },
        { name: "John Smith", group: "Group 2", avatar: srcs[1] },
        { name: "Sandra Williams", group: "Group 2", avatar: srcs[3] },
      ],
      title: "The summer breeze",
    };
  },
  props: {
    planned: {
      type: Array,
      required: true,
    },
  },
  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
  },
  methods: {
    remove(item) {
      const index = this.friends.indexOf(item.name);
      if (index >= 0) this.friends.splice(index, 1);
    },
    _goDetail() {
      console.log("xx");
      //this.$router.push({ name: "MyTrips", params: { tripId: 11 } });
      this.dialog = true;
    },
  },
  created() {
    console.log(this.planned);
  },
  mounted() {
    const truckLocation = [106.9057, 47.8864];
    const warehouseLocation = [106.9057, 47.8864];
    const lastAtRestaurant = 0;
    let keepTrack = [];
    const pointHopper = {};
    mapboxgl.accessToken =
      "pk.eyJ1IjoiZ3JhbmRpdHh4ayIsImEiOiJja25mcDYxdHYycjRnMzFwaGx0ZWVwNGhpIn0.EBks4tBwxFPv66eqLJfTWQ";
    let map = this.$refs.map.map;

    const warehouse = turf.featureCollection([turf.point(warehouseLocation)]);

    // Create an empty GeoJSON feature collection for drop off locations
    const dropoffs = turf.featureCollection([]);

    // Create an empty GeoJSON feature collection, which will be used as the data source for the route before users add any new data
    const nothing = turf.featureCollection([]);

    map.on("load", async () => {
      const marker = document.createElement("div");
      marker.classList = "truck";

      // Create a new marker
      new mapboxgl.Marker(marker).setLngLat(truckLocation).addTo(map);

      // Create a circle layer
      map.addLayer({
        id: "warehouse",
        type: "circle",
        source: {
          data: warehouse,
          type: "geojson",
        },
        paint: {
          "circle-radius": 20,
          "circle-color": "white",
          "circle-stroke-color": "#3887be",
          "circle-stroke-width": 3,
        },
      });

      // Create a symbol layer on top of circle layer
      map.loadImage("/home.png", (error, image) => {
        if (error) throw error;

        // Add the image to the map style.
        map.addImage("home", image);
      });

      map.addLayer({
        id: "warehouse-symbol",
        type: "symbol",
        source: {
          data: warehouse,
          type: "geojson",
        },
        layout: {
          "icon-image": "home",
          "icon-size": 0.75,
        },
        paint: {
          "text-color": "#3887be",
        },
      });

      map.addLayer({
        id: "dropoffs-symbol",
        type: "symbol",
        source: {
          data: dropoffs,
          type: "geojson",
        },
        layout: {
          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
          "icon-image": "marker-15",
        },
      });

      map.addSource("route", {
        type: "geojson",
        data: nothing,
      });

      map.addLayer(
        {
          id: "routeline-active",
          type: "line",
          source: "route",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#3887be",
            "line-width": ["interpolate", ["linear"], ["zoom"], 12, 3, 22, 12],
          },
        },
        "waterway-label"
      );

      map.addLayer(
        {
          id: "routearrows",
          type: "symbol",
          source: "route",
          layout: {
            "symbol-placement": "line",
            "text-field": "▶",
            "text-size": ["interpolate", ["linear"], ["zoom"], 12, 24, 22, 60],
            "symbol-spacing": [
              "interpolate",
              ["linear"],
              ["zoom"],
              12,
              30,
              22,
              160,
            ],
            "text-keep-upright": false,
          },
          paint: {
            "text-color": "#3887be",
            "text-halo-color": "hsl(55, 11%, 96%)",
            "text-halo-width": 3,
          },
        },
        "waterway-label"
      );
      if (this.planned) {
        for (var plannedDay of this.planned) {
          for (var location of plannedDay.locations) {
            const pt = turf.point(
              [location.gpsfield.longitude, location.gpsfield.latitude],
              {
                orderTime: Date.now(),
                key: Math.random(),
              }
            );
            dropoffs.features.push(pt);
            pointHopper[pt.properties.key] = pt;

            // Make a request to the Optimization API
            const query = await fetch(assembleQueryURL(), { method: "GET" });
            const response = await query.json();

            // Create an alert for any requests that return an error
            if (response.code !== "Ok") {
              const handleMessage =
                response.code === "InvalidInput"
                  ? "Refresh to start a new route. For more information: https://docs.mapbox.com/api/navigation/optimization/#optimization-api-errors"
                  : "Try a different point.";
              alert(
                `${response.code} - ${response.message}\n\n${handleMessage}`
              );
              // Remove invalid point
              dropoffs.features.pop();
              delete pointHopper[pt.properties.key];
              return;
            }

            // Create a GeoJSON feature collection
            const routeGeoJSON = turf.featureCollection([
              turf.feature(response.trips[0].geometry),
            ]);

            // Update the `route` source by getting the route source
            // and setting the data equal to routeGeoJSON
            map.getSource("route").setData(routeGeoJSON);
            console.log(dropoffs);
          }
        }
      }
      // Listen for a click on the map
      await map.on("click", addWaypoints);
    });

    async function addWaypoints(event) {
      // When the map is clicked, add a new drop off point
      // and update the `dropoffs-symbol` layer
      console.log("asdadasd");
      await newDropoff(map.unproject(event.point));
      updateDropoffs(dropoffs);
    }

    async function newDropoff(coordinates) {
      // Store the clicked point as a new GeoJSON feature with
      // two properties: `orderTime` and `key`
      const pt = turf.point([coordinates.lng, coordinates.lat], {
        orderTime: Date.now(),
        key: Math.random(),
      });
      dropoffs.features.push(pt);
      pointHopper[pt.properties.key] = pt;

      // Make a request to the Optimization API
      const query = await fetch(assembleQueryURL(), { method: "GET" });
      const response = await query.json();

      // Create an alert for any requests that return an error
      if (response.code !== "Ok") {
        const handleMessage =
          response.code === "InvalidInput"
            ? "Refresh to start a new route. For more information: https://docs.mapbox.com/api/navigation/optimization/#optimization-api-errors"
            : "Try a different point.";
        alert(`${response.code} - ${response.message}\n\n${handleMessage}`);
        // Remove invalid point
        dropoffs.features.pop();
        delete pointHopper[pt.properties.key];
        return;
      }

      // Create a GeoJSON feature collection
      const routeGeoJSON = turf.featureCollection([
        turf.feature(response.trips[0].geometry),
      ]);

      // Update the `route` source by getting the route source
      // and setting the data equal to routeGeoJSON
      map.getSource("route").setData(routeGeoJSON);
    }

    function updateDropoffs(geojson) {
      map.getSource("dropoffs-symbol").setData(geojson);
    }

    // Here you'll specify all the parameters necessary for requesting a response from the Optimization API
    function assembleQueryURL() {
      // Store the location of the truck in a variable called coordinates
      const coordinates = [truckLocation];
      const distributions = [];
      let restaurantIndex;
      keepTrack = [truckLocation];

      // Create an array of GeoJSON feature collections for each point
      const restJobs = Object.keys(pointHopper).map((key) => pointHopper[key]);

      // If there are actually orders from this restaurant
      if (restJobs.length > 0) {
        // Check to see if the request was made after visiting the restaurant
        const needToPickUp =
          restJobs.filter((d) => d.properties.orderTime > lastAtRestaurant)
            .length > 0;

        // If the request was made after picking up from the restaurant,
        // Add the restaurant as an additional stop
        if (needToPickUp) {
          restaurantIndex = coordinates.length;
          // Add the restaurant as a coordinate
          coordinates.push(warehouseLocation);
          // push the restaurant itself into the array
          keepTrack.push(pointHopper.warehouse);
        }

        for (const job of restJobs) {
          // Add dropoff to list
          keepTrack.push(job);
          coordinates.push(job.geometry.coordinates);
          // if order not yet picked up, add a reroute
          if (needToPickUp && job.properties.orderTime > lastAtRestaurant) {
            distributions.push(`${restaurantIndex},${coordinates.length - 1}`);
          }
        }
      }

      // Set the profile to `driving`
      // Coordinates will include the current location of the truck,
      return `https://api.mapbox.com/optimized-trips/v1/mapbox/driving/${coordinates.join(
        ";"
      )}?distributions=${distributions.join(
        ";"
      )}&overview=full&steps=true&geometries=geojson&source=first&access_token=${
        mapboxgl.accessToken
      }`;
    }
  },
  // mounted() {
  //   let map = this.$refs.map.map;
  //   mapboxgl.accessToken =
  //     "pk.eyJ1IjoiZ3JhbmRpdHh4ayIsImEiOiJja25mcDYxdHYycjRnMzFwaGx0ZWVwNGhpIn0.EBks4tBwxFPv66eqLJfTWQ";

  //   map.on("load", () => {
  //     var _this = this;
  //     if (this.planned) {
  //       for (var plannedDay of this.planned) {
  //         for (var location of plannedDay.locations) {
  //           console.log(location.gpsfield);
  //           this.lastClickedPoint = new mapboxgl.Marker()
  //             .setLngLat([
  //               location.gpsfield.longitude,
  //               location.gpsfield.latitude,
  //             ])
  //             .addTo(map);
  //         }
  //       }
  //     }
  //     _;
  //     map.on("click", function (e) {
  //       _this.lng = e.lngLat.lng;
  //       _this.lat = e.lngLat.lat;

  //       // console.log(this.lngclick, this.latclick);

  //       _this.submit();
  //       _this.overlay = !_this.overlay;
  //     });
  //     // async function addItem(lng, lat) {
  //     //   console.log(lng, lat);
  //     //   const location = new firebase.firestore.GeoPoint(
  //     //     Number(lat),
  //     //     Number(lng)
  //     //   );

  //     //   await fb.db.collection("mapcoords").add({
  //     //     name: "dfsdfsdf",
  //     //     createdAt: new Date(),
  //     //     status: "notcompleted",
  //     //     coords: location,
  //     //   });
  //     // }

  //     map.on("click", function (e) {
  //       new mapboxgl.Marker().setLngLat(e.lngLat).addTo(map);
  //     });
  //   });
  // },
};
</script>
<style>
.mapboxgl-map {
  overflow: auto !important;
  height: 100vh;
}

.sidebar {
  position: absolute;
  margin: 20px 20px 30px 20px;
  width: 25%;
  top: 0;
  bottom: 0;
  padding: 20px;
  background-color: #fff;
  overflow-y: scroll;
}

.card {
  font-size: small;
  border-bottom: solid #d3d3d3 2px;
  margin-bottom: 6px;
}

.card-header {
  font-weight: bold;
  padding: 6px;
}

.no-route {
  background-color: #d3d3d3;
  color: #f00;
}

.obstacle-found {
  background-color: #d3d3d3;
  color: #fff;
}

.route-found {
  background-color: #33a532;
  color: #fff;
}

.card-details {
  padding: 3px 6px;
}
</style>
